
.global-alert {

  position: fixed;
  z-index: 100;
  bottom: 2vh;
  min-width: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  button.global-alert-button {
    padding: 2px 15px;
    background: white;
    color: darkgrey;
    border: 1px solid darkgray;
    border-radius: 3px;
    margin-left: 10px;
  }

}
