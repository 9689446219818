@tailwind base;
@tailwind components;
@tailwind utilities;
@import "variables";
@import "Components/components";

body {
  font-size:16px;
  font-family:'Quicksand', sans-serif;
  margin:0;
  padding:0;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Not really the right way of doing this
.content-main {

  h1 {

  text-align: center;	

  @apply text-3xl font-bold md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight dark:text-gray-200 mb-4;

  }

  h2 {

  @apply text-gray-900 text-2xl;

  }


}
