.chevron-down {
  margin-left: auto;
  transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
}

.szh-accordion {

  &__item {

    &:not(:last-of-type){
      border-bottom: 1px solid #ccc
    };

    &-btn {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 100%;
      margin: 0;
      padding: 1rem;
      font-size: 1rem;
      text-align: left;
      background-color: transparent;
      border: none;
      //&:hover {
      //  background-color: #f3f3f3;
      //}
    }

    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
      padding: 20px 0;
      border-top: 1px solid #ccc;
    }

    &-panel {
      // padding: 1rem;
    }

    &--expanded {

      /*.szh-accordion__item-btn {
        background-color: #e7e7e7;
      }*/
      .chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}