
.title-background{

  &.background-tropical{
    background-image: url('../../assets/img/backgroundTropical.jpg');
  }

  display: flex;
  justify-content: center;
  align-items: center;

  h1{

  }


}
